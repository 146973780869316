import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { PageService } from '../services/PageService';
import { ToastsStore } from 'react-toasts';
import Loading from 'react-fullscreen-loading';
import getLanguage from './../../../core/Language';
import PageObjectEdit from '../components/PageObjectEdit';
import { GlobalDialog } from '../../../core/GlobalDialog';
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import {
  navigateToPages_Page,
  navigateToStands_Page,
} from './../../../core/Navigation';
import EditPageDetails from '../components/EditPageDetails';
import moment from 'moment';
import { TooltipService } from '../../tooltip/services/TooltipService';
import { LoadingIndicator } from '../../../core/LoadingIndicator';
import { AccessTokenService } from '../../accesstoken/services/AccessTokenService';
import VisualPageEditor from '../components/VisualPageEditor';
import EditPageKeywords from '../components/EditPageKeywords';
import LogoEditor from '../components/LogoEditor';
import { PageObjectService } from '../services/PageObjectService';
import { LoginHelper } from '../../../core/LoginHelper';
import { Help } from '@material-ui/icons';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { WebSocketContext } from '../../../socket/EchoSocket';
import ActiveUsersOnPage from '../../activity/components/ActiveUsersOnPage';
import {
  updateActiveChatNetworkId,
  updatePageTitle,
} from '../../../actions/Actions';
import { StandStats } from '../../statistics/components/StandStats';
import LostChatNetworks from '../../chat/components/LostChatNetworks';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#fff',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 400ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  iframeContainer: {
    width: '100%',
    paddingTop: '56.25%',
    position: 'relative',
  },
  iframe: {
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '100%',
    height: '100%',
  },
  editButton: {
    width: '25px;',
  },
  listWrap: {
    padding: '5px 10px 2px 10px',
    border: '1px solid #BBB',
    margin: '10px 0 5px 0',
    backgroundColor: '#EEE',
  },
  helpButon: {
    marginTop: '10px',
  },
  scollingPreview: {
    position: 'fixed',
    width: '60%',
    maxWidth: '885px',
    height: '60vh',
    maxHeight: '800px',
  },
  floatingButton: {
    float: 'right',
    marginLeft: '5px',
  },
  floatingLeftButton: {
    float: 'left',
    marginRight: '5px',
  },
  editButton: {
    backgroundColor: '#2196f3',
  },
  logoButton: {
    backgroundColor: '#ff9800',
  },
  keywordsButton: {
    backgroundColor: '#4caf50',
  },
  propertiesButton: {
    backgroundColor: '#616161',
  },
  cancelButton: {
    backgroundColor: '#e53935',
  },
  saveButton: {
    backgroundColor: '#4db6ac',
  },
  backgroundButton: {
    backgroundColor: '#009688',
  },
  editorContainer: {
    width: '1240px',
    position: 'relative',
  },
  editorContainerClear: {
    clear: 'both',
  },
  editorLeftBox: {
    float: 'left',
    width: '716px',
    display: 'inline-block',
  },
  editorRightBox: {
    float: 'left',
    width: '420px',
    display: 'inline-block',
    marginLeft: '10px',
  },
  dailyStatsPart: {
    marginTop: '40px',
  },
}));

const EditPage_Page = (props) => {
  const pageService = new PageService();
  const pageObjectService = new PageObjectService();
  const tooltipService = new TooltipService();
  const accessTokenService = new AccessTokenService();
  const loginHelper = new LoginHelper();

  const activeUsersClient = useSelector((state) => state.activeUsersClient);
  const chatNetworks = useSelector((state) => state.chatNetworks);
  const echo = useContext(WebSocketContext);

  const classes = useStyles();
  const visualEditorRef = useRef();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(null);
  const [editingObjectTypeId, setEditingObjectTypeId] = useState('');
  const [iframeUniqueId, setIframeUniqueId] = useState(
    moment().format('YYYYMMDDHHmmss')
  );
  const { id } = useParams();
  const [selectedObjectToView, setSelectedObjectToView] = useState('');
  const [useRadioToSelect, setUseRadioToSelect] = useState('');
  const [accessToken, setAccessToken] = useState(null);
  const [currentMode, setCurrentMode] = useState('Preview');
  const [pageChanged, setPageChanged] = useState(false);
  const [displayCancelDialog, setDisplayCancelDialog] = useState(false);
  const [updatedPageObjects, setUpdatedPageObjects] = useState([]);
  const [displayGoBackWarning, setDisplayGoBackWarning] = useState(false);
  const [
    groupTitleForFullscreenBackground,
    setGroupTitleForFullscreenBackground,
  ] = useState(null);
  const [creatingChatWithLoginId, setCreatingChatWithLoginId] = useState(null);
  const [copyableResourceId, setCopyableResourceId] = useState(null);
  const [embedToCopyAsResource, setEmbedToCopyAsResource] = useState(null);

  useEffect(() => {
    prepareData();
  }, []);

  useEffect(() => {
    const currentUserId = loginHelper.getCurrentLogin().id;
    const newFilteredList = activeUsersClient.filter(
      (user) => !(user.Role === "Admin" && user.id !== currentUserId)
    );
    console.log(activeUsersClient)
    console.log(newFilteredList)
  }, []);

  useEffect(() => {
    let pageObjectsForCurrent = [];
    let useRadio = false;

    if (!page || !editingObjectTypeId) {
      return;
    }

    pageObjectsForCurrent = page.pageObjects.filter(
      (t) => t.templateObject.groupTitle === editingObjectTypeId
    );
    let image = pageObjectsForCurrent.find(
      (t) => t.templateObject.kindString === 'Image'
    );
    let video = pageObjectsForCurrent.find(
      (t) => t.templateObject.kindString === 'Video'
    );
    let embed = pageObjectsForCurrent.find(
      (t) => t.templateObject.kindString === 'Html'
    );

    if (image != null && video != null && embed != null) {
      useRadio = true;

      // Try to figure out one to set as active
      if (image.data || image.resourceId) {
        setSelectedObjectToView('Image');
      } else if (video.data || video.resourceId) {
        setSelectedObjectToView('Video');
      } else if (embed.data) {
        setSelectedObjectToView('Embed');
      }
    }

    setUseRadioToSelect(useRadio);
  }, [editingObjectTypeId]);

  useEffect(() => {
    // Handle new network being created for a visitor
    if (creatingChatWithLoginId !== null) {
      let newNetwork = chatNetworks.find((t) =>
        t.chatNetworkParticipants.find(
          (y) => y.loginId === creatingChatWithLoginId
        )
      );
      if (newNetwork) {
        openChat(newNetwork.id);
      }
      setCreatingChatWithLoginId(null);
    }
  }, [chatNetworks]);

  const prepareData = async () => {
    echo.sendMessage('chat', 'RequestAllChatNetworksForPage', id, null);

    loadPage();
  };

  const loadPage = async () => {
    setPage(null);
    let response = await pageService.getPage(id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      for (const pageObject of response.pageObjects) {
        if (pageObject.customStyles) {
          pageObject.customStyles = JSON.parse(pageObject.customStyles);
        }
      }

      setPage(response);
      setUpdatedPageObjects([]);
      dispatch(updatePageTitle(response.title));

      let tooltipsResponse = await tooltipService.getTooltips(
        false,
        response.hiveId
      );
      if (tooltipsResponse.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${tooltipsResponse.response.status} - ${
            tooltipsResponse.response.statusText
          } + ")`
        );
      }

      if (response.hive.requiresLogin) {
        let accessToken = await accessTokenService.getAccessTokenForHive(
          response.hiveId
        );
        setAccessToken(accessToken);
      } else {
        setAccessToken('preview');
      }

      // Find groupTitle (if any) of background video
      for (const pageObject of response.pageObjects) {
        let styles = pageObject.templateObject.templateObjectStyles;
        if (
          styles.find((t) => t.styleKey === 'left' && t.styleValue === '0%') !=
            null &&
          styles.find((t) => t.styleKey === 'top' && t.styleValue === '0%') !=
            null &&
          styles.find(
            (t) => t.styleKey === 'width' && t.styleValue === '100%'
          ) != null &&
          styles.find(
            (t) => t.styleKey === 'height' && t.styleValue === '100%'
          ) != null
        ) {
          setGroupTitleForFullscreenBackground(
            pageObject.templateObject.groupTitle
          );
          break;
        }
      }

      setIsLoading(false);
    }
  };

  const requestedSetIsLoading = (val) => {
    setIsLoading(val);
  };

  const editClicked = (objectTypeId) => {
    setCurrentMode('Edit');
    setEditingObjectTypeId(objectTypeId);
  };

  const onPageAttributeSave = async (attribute, newValue) => {
    let newPage = page;
    newPage[attribute] = newValue;
    setPage(newPage);
    setPageChanged(true);
  };

  const removeLogo = async () => {
    setIsLoading(true);

    await pageService.removeLogoFromPage(page.id);

    setPageChanged(true);
  };

  const changeModeToPreview = () => {
    setEditingObjectTypeId('');
    setCurrentMode('Preview');
  };

  const changeModeToEdit = () => {
    setCurrentMode('Edit');
  };

  const changeModeToLogo = () => {
    setEditingObjectTypeId('');
    setCurrentMode('Logo');
  };

  const changeModeToKeywords = () => {
    setEditingObjectTypeId('');
    setCurrentMode('Keywords');
  };

  const changeModeToProperties = () => {
    setEditingObjectTypeId('');
    setCurrentMode('Properties');
  };

  const updateKeywords = (keywordList) => {
    let newPage = page;
    newPage.updateKeywords = keywordList;
    setPage(newPage);
    setPageChanged(true);
  };

  const saveClicked = async () => {
    setIsLoading(true);

    for (const pageObject of page.pageObjects) {
      if (pageObject.customStyles) {
        pageObject.customStyles = JSON.stringify(pageObject.customStyles);
      }
    }

    // for (const pageObject of updatedPageObjects) {
    //     if (pageObject.customStyles) {
    //         pageObject.customStyles = JSON.stringify(pageObject.customStyles);
    //     }
    // }

    let response = await pageService.updatePage(page.id, page);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    let pageObjectResponse = await pageObjectService.updatePageObjects(
      page.id,
      updatedPageObjects
    );

    if (pageObjectResponse.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${pageObjectResponse.response.status} - ${
          pageObjectResponse.response.statusText
        } + ")`
      );
      setIsLoading(false);
      return;
    }

    setIframeUniqueId(moment().format('YYYYMMDDHHmmss'));
    setPageChanged(false);
    setUpdatedPageObjects([]);
    setCurrentMode('Edit');
    loadPage();
    ToastsStore.success(getLanguage('PagePropertiesWereUpdated'));
  };

  const cancelClicked = () => {
    setDisplayCancelDialog(true);
  };

  const previewClicked = () => {
    if (!pageChanged) {
      changeModeToPreview();
      return;
    }
  };

  const cancelChanges = () => {
    setIsLoading(true);
    loadPage();
    setPageChanged(false);
    setDisplayCancelDialog(false);
    setEditingObjectTypeId('');
    setCurrentMode('Edit');
  };

  const onPageObjectUpdateAttribute = (pageObjectId, attribute, newValue) => {
    let newPage = page;
    let pageObject = newPage.pageObjects.find((t) => t.id === pageObjectId);
    pageObject[attribute] = newValue;

    // Clear attributes if needed
    if (attribute === 'data') {
      pageObject['resourceId'] = null;
      pageObject['linkInternalPage'] = null;
    }
    if (attribute === 'resourceId') {
      pageObject['data'] = null;
      pageObject['linkInternalPage'] = null;
    }
    if (attribute === 'linkInternalPage') {
      pageObject['resourceId'] = null;
      pageObject['data'] = null;
    }

    let newUpdatedObjects = updatedPageObjects;

    // If kind is Image, Video og Embed
    if (
      pageObject.templateObject.kindString === 'Image' ||
      pageObject.templateObject.kindString === 'Video' ||
      pageObject.templateObject.kindString === 'Html'
    ) {
      // Find any object of either type and same GroupTitle, and remove from list of updated objects
      newUpdatedObjects = newUpdatedObjects.filter(
        (t) =>
          t.templateObject.groupTitle !==
            pageObject.templateObject.groupTitle ||
          (t.templateObject.kindString !== 'Image' &&
            t.templateObject.kindString !== 'Video' &&
            t.templateObject.kindString !== 'Html')
      );

      // Clear resourceId, data & linkInternalPage of any other object wiht same groupTitle
      for (const otherObject of newPage.pageObjects.filter(
        (t) =>
          t.id !== pageObjectId &&
          t.templateObject.groupTitle === pageObject.templateObject.groupTitle
      )) {
        otherObject.resourceId = null;
        otherObject.data = null;
        otherObject.linkInternalPage = null;
      }
    }

    setPage(newPage);
    setPageChanged(true);

    // Remove duplicate
    newUpdatedObjects = newUpdatedObjects.filter((t) => t.id !== pageObject.id);

    // Insert into or update updatedPageObjects
    newUpdatedObjects.push(pageObject);
    setUpdatedPageObjects(newUpdatedObjects);

    if (
      attribute === 'resourceId' &&
      newValue &&
      (loginHelper.userHasRole('ContentLibraryAutoUpload') ||
        loginHelper.userHasRole('ContentLibraryAdministration'))
    ) {
      setCopyableResourceId(newValue);
    }
  };

  const clickedAwayFromEmbedField = (pageObject) => {
    setEmbedToCopyAsResource(pageObject.data);
  };

  const fullscreenPreviewClicked = () => {
    window.open(
      `${page.hive?.clientUrl}?tid=${iframeUniqueId}&at=${accessToken}#${page.code}`
    );
  };

  const goBackToList = () => {
    if (page.template.kindString === 'ExhibitionStand') {
      navigateToStands_Page(props);
    } else {
      navigateToPages_Page(props);
    }
  };

  const backToListClicked = () => {
    if (!pageChanged) {
      goBackToList();
      return;
    }

    setDisplayGoBackWarning(true);
  };

  const objectMoved = (pageObjectId, originalX, originalY, movedX, movedY) => {
    let newPage = page;
    let pageObject = newPage.pageObjects.find((t) => t.id === pageObjectId);

    let topValue = originalY + (movedY / 402) * 100;
    let leftValue = originalX + (movedX / 715) * 100;

    if (topValue < 0) {
      topValue = 0;
    }
    if (leftValue < 0) {
      leftValue = 0;
    }

    if (
      pageObject.customStyles?.hasOwnProperty('isPulsating') &&
      pageObject.customStyles['isPulsating'] === true
    ) {
      pageObject.customStyles = {
        top: topValue.toFixed(0) + '%',
        left: leftValue.toFixed(0) + '%',
        isPulsating: true,
      };
    } else {
      pageObject.customStyles = {
        top: topValue.toFixed(0) + '%',
        left: leftValue.toFixed(0) + '%',
      };
    }

    setPage(page);
    setPageChanged(true);

    let newUpdatedObjects = updatedPageObjects;

    // Remove duplicate
    newUpdatedObjects = newUpdatedObjects.filter((t) => t.id !== pageObject.id);

    // Insert into or update updatedPageObjects
    newUpdatedObjects.push(pageObject);

    setUpdatedPageObjects(newUpdatedObjects);
  };

  const startedMovingObject = (pageObject) => {
    setCurrentMode('MoveObject');
  };

  const saveObjectPositionClicked = () => {
    visualEditorRef.current.stopMovement();
    setCurrentMode('Edit');
  };

  const cancelMoveObjectClicked = () => {
    visualEditorRef.current.cancelMovement();
    setCurrentMode('Edit');
  };

  const clickedBackground = () => {
    visualEditorRef.current.activateEditorOfGroupTitle(
      groupTitleForFullscreenBackground
    );
  };

  const openChat = (chatNetworkId) => {
    dispatch(updateActiveChatNetworkId(chatNetworkId));
  };

  const createChatNetworkWithVisitor = (loginId) => {
    echo.sendMessage('chat', 'CreatePrivatePageChatForLogin', id, loginId);

    setCreatingChatWithLoginId(loginId);
  };

  const createChatNetworkWithAdmin = (loginId) => {
    echo.sendMessage(
      'chat',
      'CreatePrivateUserChat',
      loginId,
      loginHelper.getCurrentHive().id
    );

    setCreatingChatWithLoginId(loginId);
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='linear-gradient(180deg, #fff, #edf5fe)'
          loaderColor='#124238'
        />
      )}
      {creatingChatWithLoginId && (
        <Loading
          loading
          background='linear-gradient(180deg, #fff, #edf5fe)'
          loaderColor='#124238'
        />
      )}

      {page && (
        <>
          <div className={classes.root}>
            <div className={classes.editorContainer}>
              <div className={classes.editorLeftBox}>
                {currentMode === 'Preview' && (
                  <div className={classes.iframeContainer}>
                    <div
                      className={classes.iframe}
                      dangerouslySetInnerHTML={{
                        __html:
                          "<iframe name='" +
                          iframeUniqueId +
                          "' key='" +
                          iframeUniqueId +
                          "' style='width:100%;height:100%' src='" +
                          page.hive?.clientUrl +
                          '?tid=' +
                          iframeUniqueId +
                          '&at=' +
                          accessToken +
                          '#' +
                          page.code +
                          "' />",
                      }}
                    ></div>
                  </div>
                )}

                {currentMode !== 'Preview' && (
                  <VisualPageEditor
                    ref={visualEditorRef}
                    page={page}
                    onEditGroup={(groupTitle) => editClicked(groupTitle)}
                    currentlyEditingGroup={editingObjectTypeId}
                    objectMoved={(
                      pageObjectId,
                      originalX,
                      originalY,
                      movedX,
                      movedY
                    ) =>
                      objectMoved(
                        pageObjectId,
                        originalX,
                        originalY,
                        movedX,
                        movedY
                      )
                    }
                    startedMovingObject={(pageObject) =>
                      startedMovingObject(pageObject)
                    }
                    groupTitleForFullscreenBackground={
                      groupTitleForFullscreenBackground
                    }
                  />
                )}

                <Grid container style={{ marginTop: '5px' }}>
                  <Grid item xs={12}>
                    <Button
                      className={classes.floatingLeftButton}
                      startIcon={<ArrowBackIcon />}
                      variant='text'
                      onClick={(e) => {
                        e.preventDefault();
                        backToListClicked();
                      }}
                    >
                      {getLanguage('BackToList')}
                    </Button>
                    {currentMode !== 'Preview' &&
                      currentMode !== 'MoveObject' &&
                      !pageChanged && (
                        <Button
                          variant='contained'
                          disabled={pageChanged}
                          color='primary'
                          className={`${classes.floatingButton} ${classes.editButton}`}
                          onClick={previewClicked}
                        >
                          {getLanguage('Preview')}
                        </Button>
                      )}
                    {currentMode === 'Preview' && (
                      <Button
                        variant='contained'
                        color='primary'
                        className={`${classes.floatingButton} ${classes.editButton}`}
                        onClick={changeModeToEdit}
                      >
                        {getLanguage('Edit')}
                      </Button>
                    )}
                    {currentMode === 'Preview' && (
                      <Button
                        variant='contained'
                        color='primary'
                        className={`${classes.floatingButton} ${classes.editButton}`}
                        onClick={fullscreenPreviewClicked}
                      >
                        {getLanguage('FullscreenPreview')}
                      </Button>
                    )}
                    {currentMode !== 'Preview' &&
                      currentMode !== 'MoveObject' && (
                        <>
                          {pageChanged && (
                            <>
                              <Button
                                variant='contained'
                                color='success'
                                className={`${classes.floatingLeftButton} ${classes.saveButton}`}
                                onClick={saveClicked}
                              >
                                {getLanguage('Save')}
                              </Button>
                              <Button
                                variant='contained'
                                color='error'
                                className={`${classes.floatingLeftButton} ${classes.cancelButton}`}
                                onClick={cancelClicked}
                              >
                                {getLanguage('Cancel')}
                              </Button>
                            </>
                          )}
                          <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.floatingButton} ${classes.propertiesButton}`}
                            onClick={changeModeToProperties}
                          >
                            {getLanguage('Properties')}
                          </Button>
                          {(loginHelper.userHasRole('PageEditor') ||
                            loginHelper.userHasRole('StandEditor')) && (
                            <Button
                              variant='contained'
                              color='primary'
                              className={`${classes.floatingButton} ${classes.keywordsButton}`}
                              onClick={changeModeToKeywords}
                            >
                              {getLanguage('Keywords')}
                            </Button>
                          )}
                          {page.template.kindString === 'ExhibitionStand' && (
                            <Button
                              variant='contained'
                              color='primary'
                              className={`${classes.floatingButton} ${classes.logoButton}`}
                              onClick={changeModeToLogo}
                            >
                              {getLanguage('Logo')}
                            </Button>
                          )}
                          {groupTitleForFullscreenBackground && (
                            <Button
                              variant='contained'
                              color='primary'
                              className={`${classes.floatingButton} ${classes.backgroundButton}`}
                              onClick={clickedBackground}
                            >
                              {getLanguage('Background')}
                            </Button>
                          )}
                        </>
                      )}
                    {currentMode === 'MoveObject' && (
                      <>
                        <Button
                          variant='contained'
                          color='success'
                          className={`${classes.floatingLeftButton} ${classes.saveButton}`}
                          onClick={saveObjectPositionClicked}
                        >
                          {getLanguage('Save')}
                        </Button>
                        <Button
                          variant='contained'
                          color='error'
                          className={`${classes.floatingLeftButton} ${classes.cancelButton}`}
                          onClick={cancelMoveObjectClicked}
                        >
                          {getLanguage('Cancel')}
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
                <div className={classes.dailyStatsPart}>
                  {page.template.kindString === 'ExhibitionStand' && (
                    <StandStats
                      key={page.id}
                      pageId={page.id}
                      currentVisitors={activeUsersClient}
                    />
                  )}
                </div>
              </div>
              <div className={classes.editorRightBox}>
                {editingObjectTypeId && (
                  <>
                    <h1>{editingObjectTypeId}</h1>
                    {!page && <LoadingIndicator />}
                    {useRadioToSelect && (
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <RadioGroup
                            value={selectedObjectToView}
                            onClick={() => {
                              setSelectedObjectToView('Video');
                            }}
                          >
                            <FormControlLabel
                              value='Video'
                              control={<Radio />}
                              label={getLanguage('Video')}
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={4}>
                          <RadioGroup
                            value={selectedObjectToView}
                            onClick={() => {
                              setSelectedObjectToView('Image');
                            }}
                          >
                            <FormControlLabel
                              value='Image'
                              control={<Radio />}
                              label={getLanguage('Image')}
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={4}>
                          <RadioGroup
                            value={selectedObjectToView}
                            onClick={() => {
                              setSelectedObjectToView('Html');
                            }}
                          >
                            <FormControlLabel
                              value='Html'
                              control={<Radio />}
                              label={getLanguage('Embedcode')}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    )}
                    {page &&
                      page.pageObjects
                        .filter(
                          (t) =>
                            t.templateObject.groupTitle === editingObjectTypeId
                        )
                        .map((pageObject) => {
                          if (
                            !useRadioToSelect ||
                            selectedObjectToView ===
                              pageObject.templateObject.kindString
                          ) {
                            return (
                              <PageObjectEdit
                                handleClickAway={() =>
                                  clickedAwayFromEmbedField(pageObject)
                                }
                                key={pageObject.id}
                                pageObject={pageObject}
                                requestSetIsLoading={(val) =>
                                  requestedSetIsLoading(val)
                                }
                                updateAttribute={(attribute, newValue) =>
                                  onPageObjectUpdateAttribute(
                                    pageObject.id,
                                    attribute,
                                    newValue
                                  )
                                }
                              />
                            );
                          }
                        })}
                  </>
                )}

                {currentMode === 'Preview' &&
                  page.template.kindString === 'ExhibitionStand' &&
                  (loginHelper.userHasRole('Networking') ||
                    loginHelper.isPageOwner(page.id)) && (
                    <>
                      <ActiveUsersOnPage
                        pageId={id}
                        activeUsersClient={activeUsersClient}
                        chatNetworks={chatNetworks}
                        requestOpenChatNetwork={(id) => openChat(id)}
                        requestCreateChatNetworkWithVisitor={(loginId) =>
                          createChatNetworkWithVisitor(loginId)
                        }
                        requestCreateChatNetworkWithAdmin={(loginId) =>
                          createChatNetworkWithAdmin(loginId)
                        }
                      />
                      <div style={{ clear: 'both' }}></div>
                      <LostChatNetworks
                        pageId={id}
                        activeUsersClient={activeUsersClient}
                        chatNetworks={chatNetworks}
                        requestOpenChatNetwork={(id) => openChat(id)}
                      />
                    </>
                  )}

                {currentMode === 'Logo' && (
                  <>
                    <h1>{getLanguage('Logo')}</h1>
                    <LogoEditor
                      page={page}
                      onSave={(updatedAttributes) =>
                        onPageAttributeSave(
                          'logoResourceId',
                          updatedAttributes.logoResourceId
                        )
                      }
                      removeLogo={() =>
                        onPageAttributeSave('logoResourceId', null)
                      }
                      requestSetIsLoading={(val) => requestedSetIsLoading(val)}
                    />
                  </>
                )}

                {currentMode === 'Keywords' && (
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs>
                        <h1>{getLanguage('Keywords')}</h1>
                      </Grid>
                      <Grid item xs={1}>
                        <div
                          data-tip
                          data-for={'toolTip9'}
                          className={classes.helpButton}
                        >
                          <Help />
                        </div>
                        <ReactTooltip id={'toolTip9'} aria-haspopup='true'>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                loginHelper.userHasRole('PageEditor') ||
                                loginHelper.userHasRole('StandEditor')
                                  ? getLanguage('keywordTooltipExtra')
                                  : getLanguage('keywordTooltip'),
                            }}
                          />
                        </ReactTooltip>
                      </Grid>
                    </Grid>
                    <EditPageKeywords
                      page={page}
                      keywordsUpdated={(keywordList) =>
                        updateKeywords(keywordList)
                      }
                      preselectedKeywords={page.updateKeywords}
                    />
                  </>
                )}

                {currentMode === 'Properties' && (
                  <>
                    <h1>{getLanguage('Properties')}</h1>
                    <EditPageDetails
                      page={page}
                      isStand={page.template.kindString === 'ExhibitionStand'}
                      updateAttribute={(attribute, newValue) =>
                        onPageAttributeSave(attribute, newValue)
                      }
                      requestSetIsLoading={(val) => setIsLoading(val)}
                      removeLogo={() => removeLogo()}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={classes.editorContainerClear}></div>
          </div>
        </>
      )}

      <GlobalDialog
        isDialogOpen={displayCancelDialog}
        handleDialogClose={() => setDisplayCancelDialog(false)}
        title={getLanguage('UnsavedChanges')}
        type='Dialog'
      >
        <Container>
          {getLanguage('ThisWillRevertAllUnsavedChanges')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => {
              cancelChanges();
            }}
          >
            {getLanguage('DiscardUnsavedChanges')}
          </Button>
        </Container>
      </GlobalDialog>

      {displayGoBackWarning && (
        <GlobalDialog
          isDialogOpen={displayGoBackWarning}
          handleDialogClose={() => setDisplayGoBackWarning(false)}
          title={getLanguage('UnsavedChanges')}
          type='Dialog'
        >
          <Container>
            {getLanguage(
              'YouHaveUnsavedChangesThatWillBeLostIfYouDontSaveFirstDoYouReallyWantToGoBackWithoutSaving?'
            )}
            <br />
            <br />
            <Button
              fullWidth={true}
              variant='contained'
              color='primary'
              onClick={() => {
                goBackToList();
              }}
            >
              {getLanguage('GoBackWithoutSaving')}
            </Button>
          </Container>
        </GlobalDialog>
      )}
    </>
  );
};

export default EditPage_Page;
